import React from "react";
import PropTypes from "prop-types";
import Item from "./item/item";

const Gallery = ({ items }) => (
  <React.Fragment>
    <div className="gallery flex flex-wrap justify-between mt-8">
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </div>

    {/* --- STYLES --- */}
    <style jsx>{`
      .gallery {
        display: grid;
        grid-gap: 4rem;
        width: 90%;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
        padding: 0 4rem;
        margin: 2rem auto;
        position: relative;
      }
      @to-width tablet {
        .gallery {
          grid-template-columns: 1fr;
          grid-gap: 2rem;
        }
      }
    `}</style>
  </React.Fragment>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Gallery;
