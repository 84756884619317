import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
// import styled from "styled-components";
// import tw from "tailwind.macro";

const Item = ({ title, copy, image }) => (
  <div className="gallery-item">
    <div className="gallery-image-wrapper">
      <Img
        className="gallery-image-logo"
        fluid={image ? image.childImageSharp.fluid : {}}
        alt="ChitChat Logo"
      />
    </div>
    <div className="mt-4 md:mt-0 md:ml-6">
      <span className="galleryTitle block mt-1 text-lg leading-tight font-semibold text-gray-900 hover:underline">
        {title}
      </span>
      <p className="galleryCopy">{copy}</p>
    </div>
    <style jsx>{`
      .gallery-item {
        ${`shadow-lg relative no-underline rounded-lg px-8 py-8 md:py-24 text-white`};
        transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        &:hover {
          transform: translateY(-5px);
        }
        display: flex;
        max-width: 24rem;
        margin: 0 auto;
        padding: 1.5rem;
        border-radius: 0.5rem;
        background-color: #fff;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      }
      .gallery-image-wrapper {
        flex-shrink: 0;
        width: 3rem;
      }
      .gallery-image-logo {
        height: 3rem;
        width: 3rem;
      }
      @to-width tablet {
        .gallery-image {
          width: 50px;
          height: auto;
        }
      }
    `}</style>
  </div>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired
};

export default Item;
